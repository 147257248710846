import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { BASE_URL } from '../env';
import { NavigationEnd, Router } from '@angular/router';


@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly JWT_TOKEN = 'JWT_TOKEN';
	private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
	public loggedUser: string;

	constructor(private http: HttpClient, private router: Router) { }

	login(user: { email: string, password: string }): Observable<boolean> {
		//console.log("rouete",BASE_URL)
		return this.http.post<any>(`${BASE_URL}/login`, user)
			.pipe(
				tap(tokens => this.doLoginUser(user.email, tokens)),
				mapTo({ hello: 123 }),
				catchError(error => {
					if (error.error.error_msg == "Entered email is incorrect") {
						document.getElementById("userNameInCorrect").innerHTML = "Email ID is Incorrect"
					} else {
						document.getElementById("userNameInCorrect").innerHTML = ""
					}
					if (error.error.error_msg == "Entered password didn't match") {
						document.getElementById("passwordInCorrect").innerHTML = "Password is Incorrect"
					} else {
						document.getElementById("passwordInCorrect").innerHTML = ""
					}
					return of(error);
				}));
	}

	logout() {
		this.loggedUser = null;
		this.removeTokens();
		this.removeUserData();
		this.router.navigate(['auth/signin']);
	}

	private doLoginUser(username: string, tokens) {
		this.loggedUser = tokens.user[0];
		this.addUserData(tokens)
		this.storeTokens(tokens);
	}

	private storeTokens(tokens) {
		localStorage.setItem(this.JWT_TOKEN, tokens.token);
	}

	private addUserData(resData) {
		localStorage.setItem('role', resData.user[0].role);
		localStorage.setItem('name', resData.user[0].first_name + " " + resData.user[0].last_name);

	}

	isLoggedIn() {
		return !!this.getJwtToken();
	}

	getJwtToken() {
		return localStorage.getItem(this.JWT_TOKEN);
	}

	private removeTokens() {
		localStorage.removeItem(this.JWT_TOKEN);
	}
	private removeUserData() {
		localStorage.removeItem('role');
	}
}

