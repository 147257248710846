import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError, from } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { BASE_URL } from '../env';

@Injectable({
	providedIn: 'root'
})
export class BackendCallService {

	constructor(private http: HttpClient) { }

	uploadFile(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/upload`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadList(type: any, filterObject): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/list?type=${type}`, { params: filterObject })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getFilteredList(filterObj): Observable<any> {
		console.log(filterObj)
		return this.http.post<any>(`${BASE_URL}/leads/filter`, { params: filterObj })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadCount(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/count`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getEndrosmentLeadCount(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/endrosmentCount`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLead(UserData): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/details`, { params: { id: UserData.id } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}
	getLeadDetails(UserData): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/details/status`, { params: { id: UserData.id } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}
	getDropDown(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getDropDowns`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getHourlyData(filter): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/tracking/report`, { params: { userId: filter.userId } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getUserList(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/user/list`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	sendOtp({ name, email }): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/user/send/otp`, {
			params: {
				name,
				email
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	createUser(UserData): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/user/create`, UserData

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	createLead(UserData): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/create`, { LeadEntry: UserData }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	updateLead(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/update`, { data: UserData.updateData, id: UserData.id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	updateLeadStatus(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/update/status`, { data: UserData.updateData, leadData: UserData.updateLeadData, id: UserData.id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	removeFile(id): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/leads/remove/file?id=${id}`

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	openTicket(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/open/ticket`, { id: UserData.id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	dupliMobile(UserData): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/user/mob/exist`, {
			params: {
				mobile: UserData.mobile
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	FetchMotorDropdowns(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getMotorDropdowns`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	FetchNonMotorDropdowns(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getNonMotorDropdowns`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	FetchProductDrops(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getProductType`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchQuickSearch(clientDetail, productName, searchQuery): Observable<any> {
		console.log("Fetchinggg.........................................................")
		return this.http.post<any>(
			`${BASE_URL}/manualEntry/quickSearch`, { clientDetails: clientDetail, productName: productName, searchQuery: searchQuery }

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchCarNames(): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/car`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchBikeNames(): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/bike`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchBrandNames(brandID): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/brand/${brandID}/`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	submiteMotorEntryData(formEntryData, productName, selectedDpt): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/manualEntry/submitMotorEntry`, { formEntryData: formEntryData, productName: productName, selectedDpt: selectedDpt }

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

}
