import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendCallService } from '../../../services/backend-call.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { stringify } from 'querystring';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-sales-overview ,ngbd-accordion-basic',
	templateUrl: './sales-overview.component.html',
	styleUrls: ['./sales-overview.component.scss']
})
export class SalesOverviewComponent implements OnInit {

	@ViewChild('itemForm', { static: false }) form!: NgForm;
	@ViewChild('exampleModalCenter', { static: true }) myModal;
	constructor(private backendCall: BackendCallService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {
	}


	createLeadForm: FormGroup;
	clientDetails = [
		{ name: "Phone", value: "mobile" },
		{ name: "EmailID", value: "email_id" },
		{ name: "Name", value: "customer_name" },
		{ name: "Vehicle Number", value: "vehicle_number" },
		{ name: "Policy Number", value: "policy_number" }
	]
	productDropDown = []
	submitted: boolean;
	clientDetail = ""
	searchQuery = ""
	productName = ""
	currentData: any;
	isSearched = false


	get f() { return this.createLeadForm.controls; }

	ngOnInit() {
		this.fetchProductsDropdown()
		this.createLeadForm = this.formBuilder.group({
			searchInput: ['', Validators.required],

		});
	}


	formValidation(formElement) {
		let _element = this.createLeadForm.controls[formElement];
		if (_element.touched && _element.errors) {
			return 'form-control is-invalid';

		} else if (_element.touched && _element.valid) {
			return 'form-control is-valid';

		} else if (_element.untouched && _element.invalid && this.submitted) {
			return 'form-control is-invalid';

		} else {
			return 'form-control';
		}
	}

	showList(idName) {
		console.log(idName);

		if (document.getElementById(idName).style.display === "none") {
			document.getElementById(idName).style.display = "block";
		} else {
			document.getElementById(idName).style.display = "none";
		}


	}

	show(anything: string, idName, textId, value) {
		// document.getElementById('dp').value = anything;
		document.getElementById(textId).innerHTML = anything;
		document.getElementById(idName).style.display = "none";
		if (textId == "selectedTransaction") {
			this.clientDetail = value
		} else {
			this.productName = anything
		}
	}

	public rows: Array<any> = [
	];

	public submitQuickSearch(form: NgForm) {
		if(form.value.searchInput){
		this.submitted = true;
		this.searchQuery = form.value.searchInput
		this.fetchQuickSearch()
		this.isSearched = !this.isSearched
		}else{
			Swal.fire(
				'Alert',
				'Search Input Field Is Required!',
				'error'
			  )
		}
	}

	public fetchQuickSearch(): any {
		this.backendCall.fetchQuickSearch(this.clientDetail, this.productName, this.searchQuery).subscribe(Response => {
			if (Response) {
				console.log(Response);
				this.rows = Response.data
				if(this.rows.length == 0){
					this.isSearched=false
					Swal.fire(
						'Oops!',
						'No Such Data Available...',
						'question'
					  )
				}
			}
		},
			error => {
				alert(error.error.error_msg);
			});
	}

	public onCellClick(data: any): any {
		console.log("I Am Working", data)
		if (data.column != 'action') {
			//this.mydiv.show();
			this.currentData = [data];
			console.log(this.currentData);
		}
		// else{
		// 	this.onLeadDetails();
		// 	this.openDialog();
		// }
	}

	public fetchProductsDropdown(): any {
		this.backendCall.FetchProductDrops().subscribe(Response => {
			if (Response) {
				console.log(Response.data[0]["productname"]);
				this.productDropDown = Response.data[0]["productname"]
			}
		},
			error => {
				alert(error.error.error_msg);
			});
	}

}

export class NgbdAccordionBasic {
}


